enum Modules {
  APP = 'A',
  BOTS = 'B',
  CAMPAIGNS = 'C',
  DESK = 'D',
  ORGANIZATION = 'O',
  FLOWBUILDER = 'F',
  AI = 'AI',
  PUSHER = 'PU',
}

export interface AppError {
  code: string
  message: string
}

export function isCustomError(err: any): boolean {
  // TODO: maybe check that err.code is one of the CutomError codes
  if ('code' in err && 'message' in err) {
    return true
  }
  return false
}

export const CustomError: { [key: string]: AppError } = Object.freeze({
  // App Handled
  INVALID_LOGIN: {
    code: `${Modules.APP}01`,
    message: 'Invalid email or password. Please review your credentials',
  },
  SIGNUP_ERROR: {
    code: `${Modules.APP}2`,
    message:
      'There was an error on the signup process. Please try again or contact support.',
  },
  // App Unhandled
  LOGIN_ERROR: {
    code: `${Modules.APP}51`,
    message:
      'There was an error on the login process. Please try again or contact support.',
  },
  UNHANDLED_ERROR: {
    code: `${Modules.APP}99`,
    message:
      'Unexpected error. Please try again or contact support if the error persists.',
  },
  PROFILE_UPDATE: {
    code: `${Modules.APP}3`,
    message:
      'There was an error on the profile update process. Please try again or contact support.',
  },
  // Bot Handled
  INTEGRATION_FETCH: {
    code: `${Modules.BOTS}01`,
    message:
      'There was an error getting the details from the provider. Please, try again or contact support if the error persists.',
  },
  INTEGRATION_UPDATE: {
    code: `${Modules.BOTS}02`,
    message:
      'There was an error updating the provider. Please, try again or contact support if the error persists.',
  },
  INTEGRATION_ERROR: {
    code: `${Modules.BOTS}03`,
    message:
      'There was an error during the integration process with the bot. Please, try again or contact support if the error persists.',
  },
  // Desk Handled
  CASE_WITHOUT_PROVIDER: {
    code: `${Modules.DESK}01`,
    message: 'This case contains corrupted data please contact with support',
  },
  LOADING_CASE_ERROR: {
    code: `${Modules.DESK}51`,
    message: 'An error occured loading the case',
  },
  DOWNLOAD_CASE_ERROR: {
    code: `${Modules.DESK}53`,
    message: 'An error ocurred during the download of the case',
  },
  SAVE_PROJECT_SETTINGS_ERROR: {
    code: `${Modules.DESK}54`,
    message: 'There was a problem saving the settings',
  },
  SAVE_QUEUE_SETTINGS_ERROR: {
    code: `${Modules.DESK}55`,
    message: 'There was a problem saving the settings',
  },
  SAVE_WEBHOOK_SETTINGS_ERROR: {
    code: `${Modules.DESK}56`,
    message: 'There was a problem saving the settings',
  },
  TRANSFER_CASE_ERROR: {
    code: `${Modules.DESK}57`,
    message: 'There was a problem transferring the case',
  },
  CASE_ADD_CONTACT_REASONS: {
    code: `${Modules.DESK}58`,
    message: 'There was a problem adding the contact reasons',
  },
  CASE_REMOVE_CONTACT_REASON: {
    code: `${Modules.DESK}59`,
    message: 'There was a problem removing the contact reason',
  },
  LOAD_REALTIME_DASHBOARD: {
    code: `${Modules.DESK}60`,
    message: 'There was a problem loading the initial data',
  },

  // CASE SUMMARY
  CASE_SUMMARY_GENERATE: {
    code: `${Modules.DESK}61`,
    message:
      'There was an error generating the case summary, please, try again',
  },

  // Campaigns
  CAMPAIGN_NOT_READY: {
    code: `${Modules.CAMPAIGNS}01`,
    message: 'This campaign is not ready to be launched.',
  },
  DOWNLOAD_CONTACTS: {
    code: `${Modules.CAMPAIGNS}02`,
    message:
      'Error while preparing the download. Please, try again or contact support if the error persists.',
  },
  FETCH_CONTACTS: {
    code: `${Modules.CAMPAIGNS}03`,
    message: 'Error while loading the list of contacts',
  },
  IMPORT_CONTACTS_START: {
    code: `${Modules.CAMPAIGNS}04`,
    message:
      'Error while calling import process. Please, try again or contact support if the error persists',
  },
  CAMPAIGN_SCHEDULE: {
    code: `${Modules.CAMPAIGNS}05`,
    message:
      'Could not schedule the campaign. Please, try again or contact support if the error persists',
  },
  CAMPAIGN_TEMPLATE_LOAD: {
    code: `${Modules.CAMPAIGNS}06`,
    message:
      'Error loading templates. Please, reload the page or contact support if the error persists.',
  },
  CAMPAIGN_UPDATE: {
    code: `${Modules.CAMPAIGNS}07`,
    message:
      'Error occurred updating the campaign. Please, reload the page or contact support if the error persists.',
  },
  CAMPAIGN_MISSING_WHATSAPP_PROVIDER_ACCOUNT: {
    code: `${Modules.CAMPAIGNS}08`,
    message:
      'Campaigns module is misconfigured. There is not a Whatsapp integration configured to be used by Campaigns.',
  },
  CAMPAIGN_MISSING_TEMPLATES: {
    code: `${Modules.CAMPAIGNS}09`,
    message:
      'Campaigns module is misconfigured. There is any Whatsapp template available.',
  },
  // Organization handled
  FOLDER_TEMPLATES_ADD: {
    code: `${Modules.ORGANIZATION}01`,
    message:
      'Error while creating the folder. Please, try again or contact support if the error persists.',
  },
  FOLDER_TEMPLATES_ACTION: {
    code: `${Modules.ORGANIZATION}02`,
    message: 'Error peforming action on folder. Please try again.',
  },
  TEMPLATE_ADD: {
    code: `${Modules.ORGANIZATION}03`,
    message:
      'Error adding the new template. Please, try again or contact support if the error persists.',
  },
  TEMPLATE_DEL: {
    code: `${Modules.ORGANIZATION}04`,
    message:
      'Error while deleting the template. Please, try again or contact support if the error persists.',
  },
  TEMPLATE_NOT_PROVIDED: {
    code: `${Modules.ORGANIZATION}05`,
    message: 'Error on the selection of a template',
  },
  PASSWORD_CHANGE_FAILED: {
    code: `${Modules.ORGANIZATION}06`,
    message: 'Error, failed to reset password',
  },
  USER_ROLE_CHANGE: {
    code: `${Modules.ORGANIZATION}07`,
    message:
      'There was an error on the change of role. Please, try again or contact support if the error persists.',
  },
  USER_DELETE: {
    code: `${Modules.ORGANIZATION}08`,
    message:
      'There was an error on the user delete. Please, try again or contact support if the error persists.',
  },
  EXISTING_EMAIL: {
    code: `${Modules.ORGANIZATION}09`,
    message:
      'User with this email address is already registered in current or other organization.',
  },
  USER_CREATION: {
    code: `${Modules.ORGANIZATION}10`,
    message: 'Error, failed to create account.',
  },
  QUEUE_CREATE: {
    code: `${Modules.ORGANIZATION}11`,
    message:
      'There was an error creating the queue. Please, try again or contact support if the error persists.',
  },
  QUEUE_DELETE_OPEN_CASES: {
    code: `${Modules.ORGANIZATION}12`,
    message:
      'Queue must be empty before remove it. Please, reasign active cases to other queues',
  },
  QUEUE_DELETE: {
    code: `${Modules.ORGANIZATION}13`,
    message:
      'There was an error deleting the queue. Please, try again or contact support if the error persists.',
  },
  QUEUE_UPDATE: {
    code: `${Modules.ORGANIZATION}14`,
    message:
      'There was an error updating the queue. Please, try again or contact support if the error persists.',
  },
  ORGANIZATION_UPDATE: {
    code: `${Modules.ORGANIZATION}15`,
    message:
      'There was an error updating the Organization. Please, try again or contact support if the error persists.',
  },
  USER_UPDATE: {
    code: `${Modules.ORGANIZATION}16`,
    message: 'Error, failed to update account.',
  },
  USER_RESEND_INVITATION: {
    code: `${Modules.ORGANIZATION}17`,
    message: 'Error, failed to resend invitation.',
  },
  USER_ACTIVE: {
    code: `${Modules.ORGANIZATION}18`,
    message: 'Error, failed to reactivate account.',
  },
  USER_QUEUE_ASSIGN: {
    code: `${Modules.ORGANIZATION}19`,
    message: 'Error, failed to assign agents',
  },
  USER_QUEUE_UNASSIGN: {
    code: `${Modules.ORGANIZATION}20`,
    message: 'Error, failed to unassign agents',
  },
  // PROJECTS
  MANAGER_PROJECT_ASSIGN: {
    code: `${Modules.ORGANIZATION}21`,
    message: 'There was a problem adding the managers',
  },
  MANAGER_PROJECT_UNASSIGN: {
    code: `${Modules.ORGANIZATION}22`,
    message: 'There was a problem unassigning the managers',
  },
  USER_ASSIGN_QUEUES: {
    code: `${Modules.ORGANIZATION}23`,
    message:
      'There was an error on assign queues to a user. Please, try again or contact support if the error persists.',
  },
  USER_ASSIGN_PROJECTS: {
    code: `${Modules.ORGANIZATION}24`,
    message:
      'There was an error on assign projects to a user. Please, try again or contact support if the error persists.',
  },
  VALIDATING_EMAIL: {
    code: `${Modules.ORGANIZATION}25`,
    message: 'Error, failed to validate email.',
  },
  PROJECT_CREATE: {
    code: `${Modules.ORGANIZATION}26`,
    message:
      'There was an error creating the project. Please, try again or contact support if the error persists.',
  },
  PROJECT_DELETE_OPEN_CASES: {
    code: `${Modules.ORGANIZATION}12`,
    message: `Project's Queue must be empty before remove it. Please, reasign active cases to other queues`,
  },

  // Contact reasons
  MANDATORY_CONTACT_REASON_UPDATE: {
    code: `${Modules.ORGANIZATION}27`,
    message:
      'There was an error updating the mandatory contact reason setting. Please, try again or contact support if the error persists.',
  },

  ONLY_ONE_CONTACT_REASON_ALLOWED_UPDATE: {
    code: `${Modules.ORGANIZATION}28`,
    message:
      'There was an error updating the only one contact reason allowed setting. Please, try again or contact support if the error persists.',
  },
  NO_AUTOASSIGN_OFFLINE_WEBCHATS_UPDATE: {
    code: `${Modules.ORGANIZATION}29`,
    message:
      'There was an error updating the no autoassign offline webchats setting. Please, try again or contact support if the error persists.',
  },

  // Flowbuilder
  FLOWBUILDER_LOAD: {
    code: `${Modules.FLOWBUILDER}1`,
    message: 'There was an error loading Flowbuilder. Please, contact support.',
  },

  //AI
  TRAINING_IN_PROCESS: {
    code: `${Modules.AI}1`,
    message: 'There is already a training in process.',
  },

  // PUSHER
  PUSHER_CONNECTION_LOST: {
    code: `${Modules.PUSHER}1`,
    message:
      'You connection is down, the displayed information is not updated. Please check you connection',
  },
})
