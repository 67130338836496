import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { FileInfo, getFileInfo } from '../shared/utilities'
import { ImpMsgWhatsappTemplate } from './facebook-whatsapp-template'
import { HubtypeChat } from './hubtype-chat'
import { HubtypeProviderAccount } from './hubtype-provider-account'
import { HubtypeMessageTranslation } from './hubtype-translation-language'
import { HubtypeUserMinimal } from './hubtype-user'
import { TemplateMessage } from './template-message'

// TODO: Replace those exports for enum references
export const ACTION_SENT_BY_ENDUSER = 'message_sent_by_enduser'
export const ACTION_SENT_BY_AGENT = 'message_sent_by_agent'
export const ACTION_SENT_BY_BOT = 'message_sent_by_bot'
export const ACTION_SENT_BY_CAMPAIGN = 'message_sent_by_campaign'
export const ACTION_CASE_CREATED = 'case_created'
export const ACTION_CONTACT_REASONS_CHANGED = 'case_contact_reasons_changed'
export const ACTION_CASE_ASSIGNED_TO = 'case_assigned_to'
export const ACTION_CASE_REOPENED = 'case_reopened'
export const ACTION_CASE_TRANSFERRED_TO = 'case_transferred_to'
export const ACTION_CASE_STATUS_CHANGED = 'case_status_changed'
export const ACTION_CHAT_CLOSED = 'chat_closed_by_enduser'
export const ACTION_CHAT_ONLINE = 'chat_online'
export const ACTION_CHAT_OFFLINE = 'chat_offline'
export const ACTION_QUEUE_CLOSED = 'queue_closed'
export const ACTION_QUEUE_OPENED = 'queue_opened'
export const ACTION_NOTE = 'note'
//

export const SERVICE_LEVEL_SAFE = 'service_level_safe'
export const SERVICE_LEVEL_WARNING = 'service_level_warning'
export const SERVICE_LEVEL_ALARM = 'service_level_alarm'

export enum HubtypeMessageAction {
  CASE_ASSIGNED = 'case_assigned_to',
  CASE_CREATED = 'case_created',
  CASE_STATUS_CHANGED = 'case_status_changed',
  CASE_REOPENED = 'case_reopened',
  CASE_TRANSFERRED = 'case_transferred_to',
  CASE_CONTACT_REASONS_CHANGED = 'case_contact_reasons_changed',
  CHAT_CLOSED = 'chat_closed_by_enduser',
  CHAT_ONLINE = 'chat_online',
  CHAT_OFFLINE = 'chat_offline',
  MISSED_MESSAGE = 'missed_enduser_msg',
  NOTE = 'note',
  QUEUE_CLOSED = 'queue_closed',
  QUEUE_OPENED = 'queue_opened',
  SENT_BY_AGENT = 'message_sent_by_agent',
  SENT_BY_BOT = 'message_sent_by_bot',
  SENT_BY_CAMPAIGN = 'message_sent_by_campaign',
  SENT_BY_ENDUSER = 'message_sent_by_enduser',
}

export const FILE_SIZE_LIMIT_IN_MB = 10 * 1024 * 1024 // 10MB

export enum HubtypeAttachmentType {
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export enum HubtypeAttachmentError {
  INVALID_SIZE = 'invalid_size',
  INVALID_TYPE = 'invalid_type',
}

export class HubtypeAttachment {
  public file: File
  public type: HubtypeAttachmentType
  public error?: HubtypeAttachmentError
  public image?: any
  public isPdf = false
  public isFile = false
  public isImage = false
  public maxSizeInMB = null
  public isInvalidSize = false
  public isInvalidType = false

  constructor(file: File, maxSize = FILE_SIZE_LIMIT_IN_MB, onLoad = () => {}) {
    this.maxSizeInMB = Math.round((maxSize / 1024 / 1024) * 100) / 100
    const type = file.type.includes('image')
      ? HubtypeAttachmentType.IMAGE
      : HubtypeAttachmentType.DOCUMENT
    let error = null
    const isInvalidType =
      type !== HubtypeAttachmentType.IMAGE &&
      !ACCEPTED_FILE_TYPES_AND_EXTENSIONS.includes(file.type)
    const isInvalidSize = file.size > maxSize

    if (isInvalidSize) {
      this.isInvalidSize = true
      error = HubtypeAttachmentError.INVALID_SIZE
    } else if (isInvalidType) {
      this.isInvalidType = true
      error = HubtypeAttachmentError.INVALID_TYPE
    }

    if (type === HubtypeAttachmentType.IMAGE && !error) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.image = fileReader.result as string
        onLoad()
      }
      fileReader.readAsDataURL(file)
      this.isImage = true
    }

    if (file.type === 'application/pdf') {
      this.isPdf = true
    } else {
      this.isFile = true
    }

    this.type = type
    this.file = file
    this.error = error
  }
}

@JsonObject
export class HubtypeMessage {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('action', String, true)
  public action?: string = undefined
  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined
  @JsonProperty('deleted_at', Any, true)
  public deleted_at?: any = undefined
  @JsonProperty('agent', Any, true)
  public agent?: HubtypeUserMinimal = undefined
  @JsonProperty('queue_id', String, true)
  public queue_id?: string = undefined
  @JsonProperty('chat_id', String, true)
  public chat_id?: string = undefined
  @JsonProperty('chat', HubtypeChat, true)
  public chat?: HubtypeChat = undefined
  @JsonProperty('case_id', String, true)
  public case_id?: string = undefined
  @JsonProperty('text', String, true)
  public text?: string = undefined
  @JsonProperty('type', String, true)
  public type?: string = undefined
  @JsonProperty('image', String, true)
  public image?: string = undefined
  @JsonProperty('document', Any, true)
  public document?: any = undefined
  @JsonProperty('video', String, true)
  public video?: string = undefined
  @JsonProperty('audio', String, true)
  public audio?: string = undefined
  @JsonProperty('location', Any, true)
  public location?: any = undefined
  @JsonProperty('contact', Any, true)
  public contact?: any = undefined
  @JsonProperty('raw', Any, true)
  public raw?: any = undefined
  @JsonProperty('extra_data', Any, true)
  public extra_data?: any = undefined
  @JsonProperty('ack', Any, true)
  public ack?: any = undefined
  @JsonProperty('error_message', String, true)
  public error_message?: string = undefined
  @JsonProperty('carrousel', Any, true)
  public carrousel?: any = undefined
  @JsonProperty('buttons', Any, true)
  public buttons?: any = undefined
  @JsonProperty('list', Any, true)
  public list?: any = undefined
  @JsonProperty('whatsapp_template_message', Any, true)
  public whatsapp_template_message?: any = undefined
  @JsonProperty('payload', String, true)
  public payload?: string = undefined
  @JsonProperty('provider', String, true)
  public provider?: string = undefined
  @JsonProperty('translations', Any, true)
  public translations?: HubtypeMessageTranslation = undefined

  // these fields doesn't come from the back end API but they are filled later by FE
  // They are defined as JsonProperty to allow serialier/deserialize from state
  @JsonProperty('button_events', Any, true)
  public button_events?: any = undefined
  @JsonProperty('image_format', String, true)
  public image_format?: string = undefined
  @JsonProperty('is_draft', Boolean, true)
  public is_draft?: boolean = undefined
  @JsonProperty('file_info', Any, true)
  public file_info?: FileInfo = undefined

  constructor() {}

  static fromAgent(content: HubtypeMessageContent): HubtypeMessage {
    let message = new HubtypeMessage()
    let note = false
    switch (content.type) {
      case HubtypeMessageType.DOCUMENT:
      case HubtypeMessageType.IMAGE:
        message = (content as HubtypeMediaMessage).src
        break
      case HubtypeMessageType.TEXT:
        message.text = content.value as string
        note = (content as HubtypeTextMessage).note
        break
      case HubtypeMessageType.WHATSAPP_TEMPLATE:
        message.whatsapp_template_message = content.value
        message.text = (content as HubtypeWATemplateMessage).text
        break
      case HubtypeMessageType.RAW:
        message.raw = content.value as object
        break
      default:
    }
    message.action = note
      ? HubtypeMessageAction.NOTE
      : HubtypeMessageAction.SENT_BY_AGENT
    message.is_draft = true
    return message
  }

  public get is_message_missed() {
    return this.action === HubtypeMessageAction.MISSED_MESSAGE
  }
  public get is_message_deleted() {
    return this.type === HubtypeMessageType.DELETED
  }
  public get is_case_assigned_to() {
    return this.action === ACTION_CASE_ASSIGNED_TO
  }
  public get is_case_created() {
    return this.action === ACTION_CASE_CREATED
  }
  public get is_case_transferred_to() {
    return this.action === ACTION_CASE_TRANSFERRED_TO
  }
  public get is_case_status_changed() {
    return this.action === ACTION_CASE_STATUS_CHANGED
  }

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public get is_enduser(): boolean {
    return this.action === ACTION_SENT_BY_ENDUSER
  }

  public get is_agent(): boolean {
    return this.action === ACTION_SENT_BY_AGENT
  }

  public get is_bot(): boolean {
    return this.action === ACTION_SENT_BY_BOT
  }

  public get is_campaign(): boolean {
    return this.action === ACTION_SENT_BY_CAMPAIGN
  }

  public get is_visibleAction(): boolean {
    return this.is_agent || this.is_bot || this.is_enduser || this.is_campaign
  }

  public get is_summary(): boolean {
    return this.type === 'summary'
  }

  public get is_infoMessage() {
    return [
      ACTION_CASE_TRANSFERRED_TO,
      ACTION_CASE_STATUS_CHANGED,
      ACTION_CASE_ASSIGNED_TO,
      ACTION_CONTACT_REASONS_CHANGED,
      ACTION_CASE_CREATED,
      ACTION_CHAT_CLOSED,
      ACTION_CASE_REOPENED,
      ACTION_CHAT_ONLINE,
      ACTION_CHAT_OFFLINE,
    ].includes(this.action)
  }
  public get is_location() {
    return this.location != null
  }
  public get is_list() {
    return this.list != null
  }
  public get is_image() {
    return (
      (this.image != null && this.image != '') ||
      (typeof this.document === 'string' &&
        (this.document.includes('.jpg') || this.document.includes('.png')))
    )
  }
  public get is_document() {
    if (this.type === 'document') {
      return true
    }
    if (typeof this.document === 'string') {
      return this.document.includes('.pdf')
    }
    return this.document != null && this.document != ''
  }
  public get is_video() {
    return (
      (this.video != null && this.video != '') ||
      (typeof this.document === 'string' && this.document.includes('.mp4'))
    )
  }
  public get is_audio() {
    return this.audio != null && this.audio != ''
  }
  public get is_carrousel() {
    return this.carrousel != null && this.list == null
  }
  public get is_contact() {
    return this.contact != null
  }
  public get is_button() {
    return this.buttons != null
  }
  public get is_note() {
    return this.action === ACTION_NOTE
  }
  public get is_text() {
    return (
      this.text != null &&
      this.text !== '' &&
      !this.carrousel &&
      (!this.button_events || !this.button_events.length)
    )
  }
  public get is_button_event() {
    return this.button_events != null && this.button_events.length > 0
  }
  public get getVideo() {
    if (this.document != null) {
      return this.document
    }
    return this.video
  }

  public get isWhatsAppTemplate(): boolean {
    return (
      this.type === HubtypeMessageType.WHATSAPP_TEMPLATE &&
      this.whatsapp_template_message
    )
  }

  public get is_webchatSettings() {
    return this.type === 'webchatsettings'
  }

  public get isPostback() {
    return this.type === 'postback'
  }

  public get isAppleForm(): boolean {
    return this.type === HubtypeMessageType.RAW
  }

  public get isChatEvent() {
    return this.type === 'chatevent'
  }

  public get isChatOnline() {
    return this.action === 'chat_online'
  }

  public get isChatOffline() {
    return this.action === 'chat_offline'
  }

  public get isBotEvent() {
    return this.type === 'botevent'
  }

  isChangeOfStateMessage(from: string, to: string): boolean {
    return (
      Boolean(this.extra_data) &&
      this.extra_data.prev_status === from &&
      this.extra_data.next_status === to
    )
  }

  public isReply() {
    return Boolean(this.extra_data && this.extra_data.reply_to_text)
  }
  public get reply() {
    return this.isReply() ? this.extra_data.reply_to_text : ''
  }
  public get isSent() {
    return this.ack === 'sent'
  }
  public get isDelivered() {
    return this.ack === 'delivered'
  }
  public get isRead() {
    return this.ack === 'read'
  }
  public get errorNotSent() {
    return this.ack === 'error'
  }
  public get isWaitingAck() {
    return (
      //check date to avoid showing the waiting ack message on old webchat messages that don't have the ack information
      this.created_at > '2022-06-20T09:25:59.838Z' &&
      this.is_agent &&
      this.ack === undefined
    )
  }
  public get showMessageStatus() {
    return (
      this.provider === HubtypeProviderAccount.WHATSAPP ||
      this.provider === HubtypeProviderAccount.FACEBOOK
    )
  }
}

export enum HubtypeMessageType {
  DOCUMENT = 'document',
  IMAGE = 'image',
  TEXT = 'text',
  WHATSAPP_TEMPLATE = 'whatsapp_template',
  VIDEO = 'video',
  LOCATION = 'location',
  AUDIO = 'audio',
  RAW = 'raw',
  DELETED = 'deleted',
  CHAT_EVENT = 'chatevent',
}

export enum HubtypeMessageButtonType {
  POSTBACK = 'postback',
  PHONE_NUMBER = 'phone_number',
  URL = 'web_url',
  SHARE = 'element_share',
}

interface HubtypeMessageContent {
  value: string | ImpMsgWhatsappTemplate | object
  type: HubtypeMessageType
}

export class HubtypeWATemplateMessage implements HubtypeMessageContent {
  readonly type: HubtypeMessageType
  readonly text: string
  readonly value: ImpMsgWhatsappTemplate

  constructor(public readonly template: TemplateMessage) {
    this.type = HubtypeMessageType.WHATSAPP_TEMPLATE
    this.text = template.text
    this.value = template.model
  }
}

export class HubtypeTextMessage implements HubtypeMessageContent {
  readonly note: boolean
  readonly type: HubtypeMessageType

  constructor(
    public readonly value: string,
    isNote: boolean
  ) {
    this.type = HubtypeMessageType.TEXT
    this.note = isNote
  }
}

export class HubtypeMediaMessage implements HubtypeMessageContent {
  readonly src: HubtypeMessage
  readonly type: HubtypeMessageType
  readonly value: string

  constructor(attachment: HubtypeAttachment, description = '') {
    this.src = newMediaMessage(attachment, description)
    this.type = this.src.type as HubtypeMessageType
    this.value = description
  }
}

export class HubtypeAppleFormMesssage implements HubtypeMessageContent {
  readonly type: HubtypeMessageType
  readonly value: object

  constructor(content: object) {
    this.value = content
    this.type = HubtypeMessageType.RAW
  }
}

const FILE_TYPE_CSV = 'text/csv'
const FILE_TYPE_PDF = 'application/pdf'
const FILE_TYPE_DOC = 'application/msword'
const FILE_TYPE_XLS = 'application/vnd.ms-excel'
const FILE_TYPE_ODS =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const FILE_TYPE_ODT =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
// Apple Messages demo requires to show that we are able to send and receive these three file types
// Safai in Mac OS detects the file type but not all other browser/OS combinations
const FILE_EXTENSION_CAF = '.caf'
const FILE_TYPE_PKPASS = 'application/vnd.apple.pkpass'
const FILE_EXTENSION_PKPASS = '.pkpass'
const FILE_EXTENSION_USDZ = '.usdz'
export const ACCEPTED_FILE_TYPES_AND_EXTENSIONS = [
  'image/*',
  FILE_TYPE_CSV,
  FILE_TYPE_PDF,
  FILE_TYPE_DOC,
  FILE_TYPE_XLS,
  FILE_TYPE_ODS,
  FILE_TYPE_ODT,
  FILE_EXTENSION_CAF,
  FILE_TYPE_PKPASS,
  FILE_EXTENSION_PKPASS,
  FILE_EXTENSION_USDZ,
]

function newMediaMessage(
  attachment: HubtypeAttachment,
  caption: string
): HubtypeMessage {
  const message = new HubtypeMessage()
  message.file_info = getFileInfo(attachment.file)

  // Image
  if (attachment.image) {
    message.image = attachment.image
    message.image_format = attachment.file.type.split('/')[1]
    message.type = HubtypeMessageType.IMAGE
    message.text = caption
  } else {
    message.document = attachment.file
    message.type = HubtypeMessageType.DOCUMENT
  }
  return message
}
