export enum FeatureFlag {
  AI_INTENTS = 'ai',
  AI_KNOWLEDGEBASE = 'knowledgebase_enabled',
  CAMPAIGNS = 'campaigns',
  BLOCK_CASE_ARCHIVE = 'block_case_archive',
  APPLE_PROVIDER_ENABLED = 'apple_provider_enabled',
  INSTAGRAM_PROVIDER_ENABLED = 'instagram_provider_enabled',
  SEND_RECEIVE_ATTACHMENTS_FORBIDDEN = 'send_receive_attachments_forbidden',
  HAS_TRANSLATION_SERVICE = 'has_translation_service',
  HAS_CONTACT_REASONS_UI = 'use_contact_reasons_instead_of_typifications',
  AI_CASE_SUMMARY_ENABLED = 'ai_case_summary_enabled',
  OPTIMIZED_ANALYTICS_ENABLED = 'optimized_analytics_enabled',
  ANALYTICS_CONVERSATIONS_ENABLED = 'analytics_conversations_enabled',
  HIDE_NEW_WHATSAPP_CASE_BUTTON = 'hide_new_whatsapp_case_button',
  IMPROVED_CHAT_INPUT_BOX = 'improved_chat_input_box',
}
